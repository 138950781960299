import axios from 'axios';
import mainConfig from '../../config/main.json';

export default async ({
  periodStart, periodEnd, search, brandId, state, page, depozit, pageSize
}) => {
  const result = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}produs/listAll`,
    params: {
      'access-token': mainConfig.ACCESS_TOKEN,
      periodStart,
      periodEnd,
      search,
      brand: brandId,
      state,
      page,
      depozit,
      pageSize: pageSize || null
    }
  });

  if (result.status === 200) {
    return result.data;
  }

  return null;
};

import { Android } from '@mui/icons-material';
import { Typography } from '@mui/material';

function NotFound() {
  return (
    <>
      <Android sx={{ fontSize: '11rem', color: 'primary.main' }} />
      <Typography variant="h1" sx={{ fontSize: '8rem', marginTop: '-30px' }}>404</Typography>
      <Typography variant="body1">Pagina nu a fost gasita</Typography>
    </>
  );
}

export default NotFound;

import { configureStore } from '@reduxjs/toolkit';
import { throttle } from 'lodash';
import appReducer from './slices/appSlice';
import productsReducer from './slices/productsSlice';
import { loadState, saveState } from './utils';

const persistedState = loadState();

const store = configureStore({
  reducer: {
    app: appReducer,
    products: productsReducer
  },
  preloadedState: persistedState
});

store.subscribe(throttle(() => {
  saveState({
    app: store.getState().app
  });
}, 1000));

export default store;

import axios from 'axios';
import mainConfig from '../../config/main.json';

export default async (id, depozit) => {
  const response = { error: false };
  const requestResponse = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}produs/reportProblem/${id}/${depozit}`,
    params: {
      'access-token': mainConfig.ACCESS_TOKEN,
    }
  });

  if (requestResponse.status !== 200 || requestResponse.data?.error) {
    response.error = true;
    response.message = 'Nu am reusit sa fac ce mi-ai spus. 😭';
  }

  return response;
};

import {
  ArrowBack, Cached, Help, List
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import NumberInput from 'src/components/NumberInput';
import VerificationFailure from 'src/components/VerificationFailure';
import VerificationSuccess from 'src/components/VerificationSuccess';
import { showNotification } from 'src/redux/slices/appSlice';
import getProduct from 'src/services/products/getProduct';
import saveCompleted from 'src/services/products/saveCompleted';
import markAsProblemInDB from 'src/services/products/markAsProblem';
import { setProductProblem, setProductStocDate } from 'src/redux/slices/productsSlice';
import _ from 'lodash';

function ProductDetails() {
  const { selectedDepozit, depozite } = useSelector((state) => state.app);
  const [item, setItem] = useState({});
  const [stoc, setStoc] = useState(0);
  const [verifying, setVerifying] = useState(false);
  const [stocStatus, setStocStatus] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getMainComponent = () => {
    if (verifying) {
      return <CircularProgress sx={{ mt: 6 }} />;
    }

    switch (stocStatus) {
      case true:
        return <VerificationSuccess sx={{ mt: 6 }} />;
      case false:
        return <VerificationFailure sx={{ mt: 6 }} />;
      default:
        return <NumberInput value={stoc} setValue={setStoc} />;
    }
  };

  const verifyQty = () => {
    setVerifying(true);

    if (parseInt(item.stoc, 10) === parseInt(stoc, 10)) {
      const response = saveCompleted(id, selectedDepozit);

      if (!response.error) {
        dispatch(setProductStocDate({
          id,
          value: new Date().getTime() / 1000
        }));
        setStocStatus(true);
      } else {
        dispatch(showNotification({
          type: 'error',
          message: response.message
        }));
      }
    } else {
      setStocStatus(false);
    }

    setStoc(0);
    setVerifying(false);
  };

  const markAsProblem = async () => {
    const response = await markAsProblemInDB(id, selectedDepozit);

    if (!response.error) {
      dispatch(setProductProblem({
        id,
        value: '1'
      }));
      dispatch(showNotification({
        type: 'success',
        message: 'Am notat problema 📝'
      }));
      navigate(`${process.env.PUBLIC_URL}/`);
    } else {
      dispatch(showNotification({
        type: 'error',
        message: response.message
      }));
    }
  };

  useEffect(() => {
    const setDetailsAsync = async () => {
      const response = await getProduct(id, selectedDepozit);
      setItem(response);
    };

    setDetailsAsync();
  }, []);

  return item === undefined ? (
    <CircularProgress />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%'
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-evenly'
        }}
      >
        <Grid item xs={12}>
          <Card
            sx={{
              display: 'flex',
              m: 2
            }}
          >
            <CardMedia
              component="img"
              image={item.image}
              alt={item.denumire}
              sx={{
                maxHeight: {
                  xs: '110px',
                  sm: '90px',
                  md: '120px'
                },
                width: 'auto'
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                textAlign: 'left'
              }}
            >
              <Typography variant="body2">{item.denumire}</Typography>
              <Typography variant="caption">
                <b>Marca: </b>
                {item.brand}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: grey[200],
                  px: 1,
                  py: 0.5,
                  my: 1,
                  width: '100%',
                  fontSize: '0.9rem'
                }}
              >
                <b>{item.cod_produs}</b>
                &nbsp;|&nbsp;
                {item.cod_furnizor}
              </Typography>
              <Chip label={_.find(depozite, { id: selectedDepozit }).denumire} variant="outlined" />
            </CardContent>
          </Card>
        </Grid>
        { getMainComponent() }
      </Grid>
      { !verifying && stocStatus === null && (
        <Button
          variant="contained"
          size="large"
          sx={{
            py: 2,
            borderRadius: 0,
            mt: 10,
            position: 'fixed',
            bottom: 0,
            width: '100%'
          }}
          onClick={verifyQty}
        >
          <Cached sx={{ mr: 1 }} />
          Verifica
        </Button>
      )}

      { !verifying && stocStatus === false && (
        <Box
          sx={{
            display: 'flex',
            mt: 10,
            position: 'fixed',
            bottom: 0,
            width: '100%'
          }}
        >
          <Button
            variant="contained"
            size="large"
            sx={{
              py: 2,
              borderRadius: 0,
              flexGrow: 1,
              width: '50%'
            }}
            onClick={() => setStocStatus(null)}
          >
            <ArrowBack sx={{ mr: 1 }} />
            Recalculeaza
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{
              py: 2,
              borderRadius: 0,
              flexGrow: 1,
              width: '50%',
              backgroundColor: 'secondary.main'
            }}
            onClick={markAsProblem}
          >
            <Help sx={{ mr: 1 }} />
            Problema
          </Button>
        </Box>
      )}

      { !verifying && stocStatus === true && (
        <Button
          variant="contained"
          size="large"
          sx={{
            py: 2,
            borderRadius: 0,
            mt: 10,
            position: 'fixed',
            bottom: 0,
            width: '100%'
          }}
          onClick={() => navigate(`${process.env.PUBLIC_URL}/`)}
        >
          <List sx={{ mr: 1 }} />
          Inapoi la produse
        </Button>
      )}
    </Box>
  );
}

export default ProductDetails;

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import listDepozite from 'src/services/depozite/listDepozite';
import { setLoading } from './productsSlice';

export const initialState = {
  periodStart: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  periodEnd: moment().format('YYYY-MM-DD'),
  productState: 0,
  productBrand: 0,
  selectedDepozit: 0,
  depozite: [],
  search: '',
  notification: {}
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPeriod: (state, action) => {
      state.periodStart = action.payload.start;
      state.periodEnd = action.payload.end;
    },
    setProductState: (state, action) => {
      state.productState = action.payload;
    },
    setProductBrand: (state, action) => {
      state.productBrand = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    showNotification: (state, action) => {
      state.notification = action.payload;
    },
    resetAppState: (state) => {
      state.periodStart = moment().subtract(30, 'days').format('YYYY-MM-DD');
      state.periodEnd = moment().format('YYYY-MM-DD');
      state.productState = 0;
      state.productBrand = 0;
      state.search = '';
    },
    setDepozit: (state, action) => {
      state.selectedDepozit = action.payload;
    },
    setDepozite: (state, action) => {
      state.depozite = action.payload;
    }
  }
});

export const {
  setPeriod, setProductState, setProductBrand,
  showNotification, setSearch, resetAppState, setDepozit, setDepozite
} = appSlice.actions;

export default appSlice.reducer;

export const fetchDepozite = async (dispatch, getState) => {
  dispatch(setLoading(true));

  const state = getState();

  const response = await listDepozite();

  if (response !== null) {
    dispatch(setDepozite(response));
    if (state.app.selectedDepozit === 0) {
      dispatch(setDepozit(response[0].id));
    }
  }
  dispatch(setLoading(false));
};

import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import Router from './routes/Router';
import theme from './style/theme';
import './style/GlobalStyles.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}

export default App;

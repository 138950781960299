import { CheckCircle } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { object } from 'prop-types';

function VerificationSuccess({ sx }) {
  return (
    <Box sx={{ ...sx }}>
      <CheckCircle
        sx={{
          fontSize: '8rem',
          color: green[500]
        }}
      />
      <Typography variant="h6">Stocul calculat este corect!</Typography>
    </Box>
  );
}

VerificationSuccess.propTypes = {
  sx: object
};

export default VerificationSuccess;

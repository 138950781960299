import { createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash';
import update from 'immutability-helper';
import listProducts from 'src/services/products/listProducts';

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    items: [],
    totalCount: 0,
    page: 1,
    loading: false
  },
  reducers: {
    setProducts: (state, action) => {
      state.items = state.items.concat(action.payload);
    },
    resetProducts: (state) => {
      state.items = [];
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setProductProblem: (state, action) => {
      const index = _.findIndex(current(state.items), { id: action.payload.id });

      if (index !== -1) {
        state.items = update(state.items, {
          [index]: {
            probleme: {
              $set: action.payload.value
            }
          }
        });
      }
    },
    setProductStocDate: (state, action) => {
      const index = _.findIndex(current(state.items), { id: action.payload.id });
      if (index !== -1) {
        state.items = update(state.items, {
          [index]: {
            data_ultimului_stoc: {
              $set: action.payload.value
            },
            probleme: {
              $set: '0'
            }
          }
        });
      }
    }
  }
});

export const {
  setProducts,
  setTotalCount,
  setPage,
  resetProducts,
  setLoading,
  setProductProblem,
  setProductStocDate
} = productsSlice.actions;

export default productsSlice.reducer;

export const fetchProducts = async (dispatch, getState) => {
  dispatch(setLoading(true));
  const state = getState();

  const response = await listProducts({
    periodStart: state.app.periodStart,
    periodEnd: state.app.periodEnd,
    search: state.app.search,
    brandId: state.app.productBrand,
    state: state.app.productState,
    page: state.products.page,
    depozit: state.app.selectedDepozit
  });

  if (response !== null) {
    dispatch(setProducts(response.items));
    dispatch(setTotalCount(response.totalCount));
  }
  dispatch(setLoading(false));
};

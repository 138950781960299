import { Alert, Snackbar } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { showNotification } from 'src/redux/slices/appSlice';
import Header from '../Header';

function Main() {
  const notification = useSelector((state) => state.app.notification);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSnakClose = () => {
    setOpen(false);
    dispatch(showNotification({}));
  };

  useEffect(() => {
    if (notification.message !== undefined) setOpen(true);
    else setOpen(false);
  }, [notification]);

  return (
    <>
      <Header />
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Outlet />
      </Box>
      { notification.message !== undefined && (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleSnakClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notification.type} onClose={handleSnakClose} variant="filled">
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Main;

import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { Grid, TextField, Typography } from '@mui/material';
import {
  func, number, oneOfType, string
} from 'prop-types';

function NumberInput({ value, setValue }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" sx={{ mt: 6 }}>Numarul de produse calculate:</Typography>
      </Grid>
      <Grid item xs={3}>
        <RemoveCircle
          onClick={() => setValue(value - 1)}
          sx={{
            color: 'secondary.main',
            fontSize: '3.5rem',
            ml: 2
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="number"
          name="stoc"
          value={value}
          onInput={(event) => setValue(event.target.value)}
          sx={{
            background: 'white'
          }}
          InputProps={{
            inputProps: {
              style: {
                textAlign: 'center',
                padding: '10px',
                fontSize: '28px'
              }
            }
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <AddCircle
          onClick={() => setValue(value + 1)}
          sx={{
            color: 'primary.main',
            fontSize: '3.5rem',
            mr: 2
          }}
        />
      </Grid>
    </Grid>
  );
}

NumberInput.propTypes = {
  value: oneOfType([number, string]),
  setValue: func
};

export default NumberInput;

import { useRoutes } from 'react-router-dom';
import Main from 'src/layout/Main';
import NotFound from 'src/pages/NotFound';
import PrintProducts from 'src/pages/PrintProducts';
import ProductDetails from 'src/pages/ProductDetails';
import ProductList from 'src/pages/ProductList';

function Router() {
  const element = useRoutes([
    {
      path: `${process.env.PUBLIC_URL}/`,
      element: <Main />,
      children: [
        { index: true, element: <ProductList /> },
        { path: 'print', element: <PrintProducts /> },
        { path: 'product/:id', element: <ProductDetails /> },
        { path: '*', element: <NotFound /> }
      ]
    }
  ]);

  return element;
}

export default Router;

import { IconButton, InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { func, object } from 'prop-types';
import { useRef } from 'react';
import { Clear } from '@mui/icons-material';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

function SearchInput({ inputProps, sx, clearInputValue }) {
  const inputRef = useRef();

  return (
    <Search sx={sx}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Cauta produsul..."
        style={{ width: '100%' }}
        endAdornment={inputProps.value && (
          <IconButton onClick={clearInputValue} sx={{ color: 'white' }}>
            <Clear />
          </IconButton>
        )}
        inputRef={inputRef}
        {...inputProps}
        onClick={() => {
          inputProps.onClick();
          setTimeout(() => {
            inputRef.current.focus();
          }, 100);
        }}
      />
    </Search>
  );
}

SearchInput.propTypes = {
  sx: object,
  inputProps: object,
  clearInputValue: func
};

export default SearchInput;

import { Error } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { object } from 'prop-types';

function VerificationFailure({ sx }) {
  return (
    <Box sx={{ mx: 2, ...sx }}>
      <Error
        sx={{
          fontSize: '8rem',
          color: red[500]
        }}
      />
      <Typography variant="h6">Stocul din sistem difera de cel calculat!</Typography>
    </Box>
  );
}

VerificationFailure.propTypes = {
  sx: object
};

export default VerificationFailure;

import axios from 'axios';
import mainConfig from '../../config/main.json';

export default async () => {
  const result = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}brand/listAll`,
    params: {
      'access-token': mainConfig.ACCESS_TOKEN
    }
  });

  if (result.status === 200) {
    return result.data;
  }

  return null;
};

import {
  CheckCircleTwoTone,
  HelpOutline,
  HighlightOffTwoTone,
  Print
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Fab,
  Grid,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import moment from 'moment';
import {
  green, grey, orange, red
} from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProducts,
  resetProducts,
  setPage
} from 'src/redux/slices/productsSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import FALLBACK_IMAGE from '../assets/images/defaultImage.jpg';

function ProductList() {
  const products = useSelector((state) => state.products);
  const depozit = useSelector((state) => state.app.selectedDepozit);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePrint = () => {
    window.open('/print');
  };

  const getStatus = (prod) => {
    if (prod.probleme === '1') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <HelpOutline sx={{ color: orange[500] }} />
          <Typography variant="caption" sx={{ lineHeight: 1, ml: 1 }}>
            Probleme la stoc
          </Typography>
        </Box>
      );
    }

    return prod.data_ultimului_stoc !== '0' ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <CheckCircleTwoTone sx={{ color: green[500] }} />
        <Typography variant="caption" sx={{ lineHeight: 1, ml: 1 }}>
          Ultimul stoc in data de&nbsp;
          <b>{moment.unix(prod.data_ultimului_stoc).format('DD.MM.YYYY')}</b>
        </Typography>
      </Box>
    ) : (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <HighlightOffTwoTone sx={{ color: red[500] }} />
        <Typography variant="caption" sx={{ lineHeight: 1, ml: 1 }}>
          Produs nenumarat in perioada selectata
        </Typography>
      </Box>
    );
  };

  const fetchData = () => {
    dispatch(setPage(products.page + 1));
    dispatch(fetchProducts);
  };

  useEffect(() => {
    if ((products.items === undefined || products.items?.length === 0) && depozit !== 0) {
      dispatch(resetProducts);
      dispatch(setPage);
      dispatch(fetchProducts);
    }
  }, [depozit]);

  if (products.items?.length > 0) {
    return (
      <>
        <Fab
          color="primary"
          aria-label="print"
          onClick={handlePrint}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
        >
          <Print />
        </Fab>
        <InfiniteScroll
          style={{ width: '100%' }}
          dataLength={products.items.length}
          next={fetchData}
          hasMore={products.items.length < products.totalCount}
          loader={(
            <>
              <CircularProgress />
              <h4>Se incarca...</h4>
            </>
          )}
        >
          <Grid
            container
            spacing={2}
            sx={{
              p: 2
            }}
          >
            {products.items.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card
                  onClick={() => navigate(`${process.env.PUBLIC_URL}/product/${item.id}`)}
                  sx={{
                    display: 'flex',
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow:
                        '0px 2px 10px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    image={item.image ?? FALLBACK_IMAGE}
                    alt={item.denumire}
                    sx={{
                      maxHeight: {
                        xs: '110px',
                        sm: '90px',
                        md: '120px'
                      },
                      width: 'auto'
                    }}
                  />
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      textAlign: 'left'
                    }}
                  >
                    <Typography variant="body2">{item.denumire}</Typography>
                    <Typography variant="caption">
                      <b>Marca: </b>
                      {item.brand}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        backgroundColor: grey[200],
                        px: 1,
                        py: 0.5,
                        my: 1,
                        width: '100%',
                        fontSize: '0.9rem'
                      }}
                    >
                      <b>{item.cod_produs}</b>
                      &nbsp;|&nbsp;
                      {item.cod_furnizor}
                    </Typography>
                    {getStatus(item)}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </>
    );
  }

  if (products.loading) {
    return (
      <>
        <CircularProgress />
        <h4>Se incarca...</h4>
      </>
    );
  }

  return <b>Nu am gasit produse... 🤨</b>;
}

export default ProductList;

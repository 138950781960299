import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Button,
  Chip
} from '@mui/material';
import { Box } from '@mui/system';
import {
  Apps,
  ArrowBack,
  CalendarMonth,
  Check,
  DoNotDisturbAlt,
  FilterAlt,
  HelpOutline
} from '@mui/icons-material';
import SearchInput from 'src/components/SearchInput';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  blue, green, orange, red
} from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetAppState,
  setPeriod,
  setProductBrand,
  setProductState,
  setSearch,
  initialState,
  setDepozit,
  fetchDepozite
} from 'src/redux/slices/appSlice';
import listBrands from 'src/services/brands/listBrands';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fetchProducts,
  resetProducts,
  setPage
} from 'src/redux/slices/productsSlice';
import moment from 'moment';
import _ from 'lodash';

function Header() {
  const app = useSelector((state) => state.app);
  const {
    periodStart, periodEnd, productState, productBrand, search, selectedDepozit, depozite
  } = app;
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date(periodStart));
  const [endDate, setEndDate] = useState(new Date(periodEnd));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [searched, setSearched] = useState(app.search !== '');

  const productStateValues = [
    {
      name: 'Numarate',
      icon: (
        <Check sx={{ color: green[500], mr: 2, verticalAlign: 'middle' }} />
      ),
      value: 1
    },
    {
      name: 'Nenumarate',
      icon: (
        <DoNotDisturbAlt
          sx={{ color: red[500], mr: 2, verticalAlign: 'middle' }}
        />
      ),
      value: 2
    },
    {
      name: 'Cu probleme',
      icon: (
        <HelpOutline
          sx={{ color: orange[500], mr: 2, verticalAlign: 'middle' }}
        />
      ),
      value: 3
    }
  ];

  const searchChange = (event) => {
    dispatch(setSearch(event.target.value));
  };

  const executeSearch = (event) => {
    if (event.key === 'Enter') {
      dispatch(setPage(1));
      dispatch(resetProducts());
      dispatch(fetchProducts);
      setSearched(true);
    }
  };

  const clearSearch = () => {
    dispatch(setSearch(''));
    if (searched) {
      setSearched(false);
      dispatch(setPage(1));
      dispatch(resetProducts());
      dispatch(fetchProducts);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleProductStateChange = (event) => {
    dispatch(setProductState(event.target.value));
  };

  const handleProductBrandChange = (event) => {
    dispatch(setProductBrand(event.target.value));
  };

  const handleProductDepozitChange = (event) => {
    dispatch(setDepozit(event.target.value));
  };

  const handlePeriodChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    dispatch(
      setPeriod({
        start: moment(start).format('YYYY-MM-DD'),
        end: end !== null ? moment(end).format('YYYY-MM-DD') : null
      })
    );
  };

  const resetFilters = () => {
    dispatch(resetAppState());
    dispatch(resetProducts());
    dispatch(fetchProducts);
    setDrawerOpen(false);
  };

  const applyChanges = () => {
    dispatch(setPage(1));
    dispatch(resetProducts());
    dispatch(fetchProducts);
    setDrawerOpen(false);
  };

  useEffect(() => {
    async function getBrands() {
      setBrands(await listBrands());
    }

    async function getDepozite() {
      dispatch(fetchDepozite);
    }

    getBrands();
    getDepozite();
  }, []);

  return (
    <Box
      className="no-print"
      sx={{
        display: location.pathname === `${process.env.PUBLIC_URL}/print` ? 'none' : 'block'
      }}
    >
      <AppBar position="fixed" sx={{ zIndex: 1300 }}>
        <Toolbar>
          {location.pathname !== process.env.PUBLIC_URL
          && location.pathname !== `${process.env.PUBLIC_URL}/` && (
            <IconButton
              size="large"
              aria-label="Filtru"
              color="inherit"
              onClick={() => navigate(-1)}
              sx={{ mr: 2 }}
            >
              <ArrowBack />
            </IconButton>
          )}
          <SearchInput
            sx={{ flexGrow: 1 }}
            inputProps={{
              value: search,
              onChange: searchChange,
              onKeyUp: executeSearch,
              onClick: () => setDrawerOpen(false)
            }}
            clearInputValue={clearSearch}
          />
          {(location.pathname === process.env.PUBLIC_URL
          || location.pathname === `${process.env.PUBLIC_URL}/`) && (
          <IconButton
            size="large"
            aria-label="Filtru"
            color="inherit"
            onClick={() => toggleDrawer()}
            sx={{ ml: 2 }}
          >
            <FilterAlt />
          </IconButton>
          )}
        </Toolbar>
        {JSON.stringify(
          _.pick(app, [
            'periodStart',
            'periodEnd',
            'productBrand',
            'productState'
          ])
        )
        !== JSON.stringify(
          _.pick(initialState, [
            'periodStart',
            'periodEnd',
            'productBrand',
            'productState'
          ])
        )
        && !drawerOpen
        && (
          <Toolbar
            sx={{
              background: 'white',
              color: 'secondary.main',
              fontSize: '0.8rem',
              gap: 1,
              p: 2,
              overflow: 'auto'
            }}
          >
            {(initialState.periodStart !== app.periodStart
            || initialState.periodEnd !== app.periodEnd) && (
              <Chip
                label={`${app.periodStart} - ${app.periodEnd}`}
                onDelete={() => {
                  dispatch(
                    setPeriod({
                      start: initialState.periodStart,
                      end: initialState.periodEnd
                    })
                  );
                  applyChanges();
                }}
              />
            )}

            {initialState.productState !== app.productState && (
              <Chip
                label={
                  _.find(productStateValues, { value: app.productState }).name
                }
                onDelete={() => {
                  dispatch(setProductState(initialState.productState));
                  applyChanges();
                }}
              />
            )}

            {initialState.productBrand !== app.productBrand && (
              <Chip
                label={_.find(brands, { id: app.productBrand })?.nume}
                onDelete={() => {
                  dispatch(setProductBrand(initialState.productBrand));
                  applyChanges();
                }}
              />
            )}
          </Toolbar>
        )}

        <Drawer anchor="top" open={drawerOpen} onClose={() => toggleDrawer()}>
          <Toolbar />
          <Grid container spacing={2} sx={{ p: 4 }}>
            <Grid item xs={12} md={4} sm={6}>
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                customInput={(
                  <TextField
                    variant="outlined"
                    label="Perioada"
                    sx={{ width: '100%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonth />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
                onChange={handlePeriodChange}
                withPortal
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="product-depozit-filter-label">Depozit</InputLabel>
                <Select
                  labelId="product-depozit-filter-label"
                  id="product-depozit-filter"
                  label="Depozit"
                  value={selectedDepozit}
                  onChange={handleProductDepozitChange}
                >
                  {depozite.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.denumire}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="product-state-filter-label">
                  Stare produse
                </InputLabel>
                <Select
                  labelId="product-state-filter-label"
                  id="product-state-filter"
                  label="Stare produse"
                  value={productState}
                  onChange={handleProductStateChange}
                >
                  <MenuItem key={0} value={0}>
                    <Apps
                      sx={{ color: blue[500], mr: 2, verticalAlign: 'middle' }}
                    />
                    Toate
                  </MenuItem>
                  {productStateValues.map((value) => (
                    <MenuItem key={value.value} value={value.value}>
                      {value.icon}
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="product-brand-filter-label">Brand</InputLabel>
                <Select
                  labelId="product-brand-filter-label"
                  id="product-brand-filter"
                  label="Brand"
                  value={productBrand}
                  onChange={handleProductBrandChange}
                >
                  <MenuItem key={0} value={0}>
                    Toate
                  </MenuItem>
                  {brands.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.nume}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <Button onClick={resetFilters}>Resetare</Button>
              <Button
                variant="contained"
                startIcon={<Check />}
                onClick={applyChanges}
              >
                Aplica
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </AppBar>
      <Toolbar />
      {JSON.stringify(
        _.pick(app, [
          'periodStart',
          'periodEnd',
          'productBrand',
          'productState'
        ])
      )
      !== JSON.stringify(
        _.pick(initialState, [
          'periodStart',
          'periodEnd',
          'productBrand',
          'productState'
        ])
      ) && <Toolbar sx={{ height: '64px' }} />}
    </Box>
  );
}

export default Header;

import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import mainConfig from '../config/main.json';

function PrintProducts() {
  const appState = useSelector((state) => state.app);

  const { data: products, isFetched, refetch } = useQuery('getProducts', () => axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}produs/listAll`,
    params: {
      'access-token': mainConfig.ACCESS_TOKEN,
      periodStart: appState.periodStart,
      periodEnd: appState.periodEnd,
      search: appState.search,
      brand: appState.productBrand,
      state: appState.productState,
      page: 1,
      depozit: appState.selectedDepozit,
      pageSize: 10000000000000
    }
  }));

  const getStateIcon = (prod) => {
    if (prod.probleme === '1') return 'X';
    if (prod.data_ultimului_stoc !== '0') return '✓';
    return '';
  };

  useEffect(() => {
    async function initProducts() {
      refetch();
    }

    initProducts();
  }, []);

  const cellStyleing = {
    fontSize: '10px',
    padding: '5px',
    borderRight: '1px solid #e0e0e0'
  };

  const headerCellStyle = {
    textAlign: 'center'
  };

  if (isFetched) {
    if (products.data.items.length > 0) {
      return (
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow sx={{ background: '#dedede' }}>
                <TableCell sx={{ ...cellStyleing, ...headerCellStyle, width: '14%' }}>Cod produs</TableCell>
                <TableCell sx={{ ...cellStyleing, ...headerCellStyle, width: '14%' }}>Cod furnizor</TableCell>
                <TableCell sx={{ ...cellStyleing, ...headerCellStyle, width: '50%' }}>Denumire</TableCell>
                <TableCell sx={{ ...cellStyleing, ...headerCellStyle, width: '7%' }}>Stare</TableCell>
                <TableCell sx={{ ...cellStyleing, ...headerCellStyle, width: '15%' }}>Comentarii</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.data.items.map((product) => (
                <TableRow>
                  <TableCell sx={{ ...cellStyleing }}>{product.cod_produs}</TableCell>
                  <TableCell sx={{ ...cellStyleing }}>{product.cod_furnizor}</TableCell>
                  <TableCell sx={{ ...cellStyleing }}>{product.denumire}</TableCell>
                  <TableCell sx={{ ...cellStyleing, textAlign: 'center' }}>{getStateIcon(product)}</TableCell>
                  <TableCell sx={{ ...cellStyleing }}>&nbsp;</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return <b>Nu am gasit produse... 🤨</b>;
  }

  return (
    <>
      <CircularProgress />
      <h4>Se incarca...</h4>
    </>
  );
}

export default PrintProducts;
